<!--
  一般机构账号
  + 修改表单
  + 禁用账号
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="用户基本资料" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <info-header title="基本资料" />
        <div style="padding: 20px 0;">
          <info-item
            label="姓名"
            :value="formData.username">
          </info-item>
          <info-item
            label="手机号"
            :value="formData.mobile">
          </info-item>
          <info-item
            label="联系邮箱"
            :value="formData.contactEmail">
          </info-item>
          <info-item
            label="账号类型"
            :value="{
              [globalVar.USER_ROLE_0]: '普通用户',
              [globalVar.USER_ROLE_1]: '服务用户',
              [globalVar.USER_ROLE_2]: '服务集成商',
            }[formData.role]">
          </info-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  data () {
    return {
      // 页面loading
      loading: false,
      formData: {
        username: '',
        mobile: '',
        contactEmail: '',
        role: '',
        test: ''
      }
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  created () {
    this.getUserAccountInfo()
  },
  methods: {
    // 获取账号详情
    getUserAccountInfo () {
      this.loading = true
      api.getUserItemInfo(this.id).then(res => {
        if (res.data.code === 0) {
          for (const key in this.formData) {
            if (Object.hasOwnProperty.call(this.formData, key)) {
              this.formData[key] = res.data.data[key]
            }
          }
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;
    }
  }
}
</style>
